import * as React from "react"
import Layout from "../components/layout"
import Services from "../components/services"
import Articles from "../components/articles"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'


const IndexPage = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'home-page'
    }}
/>
 <Layout pageTitle="Home">
<Services></Services>
        <section id="work">
      <div className="work">

        <AniLink cover to="/work/qvm-winter-night-market" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage: 'url(/img/works/project-featured-qvm-nightmarket.png)'}}>
          </div>
        </AniLink>
        <AniLink cover to="/work/bmg" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage:'url(/img/works/bmg_feature.jpg)'}}>
          </div>
        </AniLink>


        <AniLink cover to="/work/south-melbourne-market" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture hasSVG">
            <img src={'/img/works/smm.svg'} alt="South Melbourne Market" />
          </div>
        </AniLink>

        <AniLink cover to="/work/we-make-online-videos" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage:'url(/img/works/wemov-feature2.jpg)'}}>
          </div>
        </AniLink>

        <AniLink cover to="/work/bulk-buys" bg="#00ccd4" direction="right" duration={0.76} className="projectBox">
          <div className="picture" style={{backgroundImage:'url(/img/works/bulkbuys.svg)'}}>
          </div>
        </AniLink>


        <div className="seeWork">
          <AniLink cover to="/work" bg="#00ccd4" direction="right" duration={0.76} className="button"><span className="seemore"><i>&#xe801;</i></span>See more work</AniLink>
        </div>

      </div>
    </section>
<Articles></Articles>
</Layout>
  </>
  )
}

export default IndexPage

export const Head = () => (
  <>
    <title>Web Development Melbourne - Tobstar</title>
    <meta name="description" content="App and Web Development Melbourne. By combining structure, design, web development and technology, we engineer websites and apps" />
  </>
)